
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexwpv_O0hcz5C_czPvKt9oFbw_NOJK73Bo27km6S_45pyMsMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/index.vue?macro=true";
import { default as indexVESldkalUIbWI11UGVKefTD764gxhZ_ozdWAK6RcrMcMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue?macro=true";
import { default as createYfAXi_45JjhiuGDGUZWMt5DK5iNoBZ0uVA2xrl_45hslySwMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue?macro=true";
import { default as signind_9RTrQfuTSy83KTfs6FpbVT2XdvMn0GGa1pB64siLYMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue?macro=true";
import { default as _91doc_93F5K6vdhGOE9h1ppE_45iIsouzUGEyH12zcu9LGeZXFw4wMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue?macro=true";
import { default as indexA_rSucPAfpWcPNxmkjtPnmFu_45cUlsdZ9ixv4mk_1EpQMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue?macro=true";
import { default as albumajTyLdTkq6A_9F3Ti9IvCmE4IlE_457NdnUVCvScx_xuMMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue?macro=true";
import { default as indexa4Lvslnof7YT3ZluatPbJxOxE52johSyGDFSlSou_45dcMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue?macro=true";
import { default as inviteRhwKcOXymWoYp3cl10baq86hBO4ovH2rIpCTh2No9j4Meta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue?macro=true";
import { default as capturem_CpmhV7nahRCgw9mGQpDLLXbANHzR_45AZPk0uIQt6zwMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue?macro=true";
import { default as brandingWK2ed_450vg6X3UQOR19w3F_bBKgXaSkGX3yCLSBOQhi0Meta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/branding.vue?macro=true";
import { default as carouselm3aI3mcibB4mmCirHGgeWstREk2sETgxp7yCP7xHERIMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue?macro=true";
import { default as fullhouseFh_iK6aAkORdTvCd0Jw2I_45nAvqtwrto9P0D5ZUUno1cMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/fullhouse.vue?macro=true";
import { default as moderationUeMLe4zVHa6J3YfWS5c9MZSA8Q_45iLZEJ5POdagj0r4kMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/moderation.vue?macro=true";
import { default as guest_45signin_45EEk54kogeh8oqLS_45uGi_rdr2yf_Q71IR8i91c9RrxwMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue?macro=true";
import { default as guest_45profiletKdERjnGgPzqotwyKoS_7z6ogQotSmhJVaE674IZBFcMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue?macro=true";
import { default as guest_45signin_45codeRXdHD_4575m0r_dn5fPhOzD7pS0imcIDyCtLwKUwbEN7oMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue?macro=true";
export default [
  {
    name: "index___el",
    path: "/el",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  },
  {
    name: "events___el",
    path: "/el/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events___en___default",
    path: "/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events-create___el",
    path: "/el/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events-create___en___default",
    path: "/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events-create___en",
    path: "/en/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events-signin___el",
    path: "/el/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "events-signin___en___default",
    path: "/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "events-signin___en",
    path: "/en/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "booths-eventId-doc___el",
    path: "/el/booths/:eventId()/:doc()",
    meta: _91doc_93F5K6vdhGOE9h1ppE_45iIsouzUGEyH12zcu9LGeZXFw4wMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId-doc___en___default",
    path: "/booths/:eventId()/:doc()",
    meta: _91doc_93F5K6vdhGOE9h1ppE_45iIsouzUGEyH12zcu9LGeZXFw4wMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId-doc___en",
    path: "/en/booths/:eventId()/:doc()",
    meta: _91doc_93F5K6vdhGOE9h1ppE_45iIsouzUGEyH12zcu9LGeZXFw4wMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId___el",
    path: "/el/booths/:eventId()",
    meta: indexA_rSucPAfpWcPNxmkjtPnmFu_45cUlsdZ9ixv4mk_1EpQMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "booths-eventId___en___default",
    path: "/booths/:eventId()",
    meta: indexA_rSucPAfpWcPNxmkjtPnmFu_45cUlsdZ9ixv4mk_1EpQMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "booths-eventId___en",
    path: "/en/booths/:eventId()",
    meta: indexA_rSucPAfpWcPNxmkjtPnmFu_45cUlsdZ9ixv4mk_1EpQMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "events-eventId-album___el",
    path: "/el/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId-album___en___default",
    path: "/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId-album___en",
    path: "/en/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId___el",
    path: "/el/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId___en___default",
    path: "/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId___en",
    path: "/en/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "booths-eventId-invite___el",
    path: "/el/booths/:eventId()/invite",
    meta: inviteRhwKcOXymWoYp3cl10baq86hBO4ovH2rIpCTh2No9j4Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "booths-eventId-invite___en___default",
    path: "/booths/:eventId()/invite",
    meta: inviteRhwKcOXymWoYp3cl10baq86hBO4ovH2rIpCTh2No9j4Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "booths-eventId-invite___en",
    path: "/en/booths/:eventId()/invite",
    meta: inviteRhwKcOXymWoYp3cl10baq86hBO4ovH2rIpCTh2No9j4Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "booths-eventId-capture___el",
    path: "/el/booths/:eventId()/capture",
    meta: capturem_CpmhV7nahRCgw9mGQpDLLXbANHzR_45AZPk0uIQt6zwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "booths-eventId-capture___en___default",
    path: "/booths/:eventId()/capture",
    meta: capturem_CpmhV7nahRCgw9mGQpDLLXbANHzR_45AZPk0uIQt6zwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "booths-eventId-capture___en",
    path: "/en/booths/:eventId()/capture",
    meta: capturem_CpmhV7nahRCgw9mGQpDLLXbANHzR_45AZPk0uIQt6zwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "events-eventId-branding___el",
    path: "/el/events/:eventId()/branding",
    meta: brandingWK2ed_450vg6X3UQOR19w3F_bBKgXaSkGX3yCLSBOQhi0Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/branding.vue")
  },
  {
    name: "events-eventId-branding___en___default",
    path: "/events/:eventId()/branding",
    meta: brandingWK2ed_450vg6X3UQOR19w3F_bBKgXaSkGX3yCLSBOQhi0Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/branding.vue")
  },
  {
    name: "events-eventId-branding___en",
    path: "/en/events/:eventId()/branding",
    meta: brandingWK2ed_450vg6X3UQOR19w3F_bBKgXaSkGX3yCLSBOQhi0Meta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/branding.vue")
  },
  {
    name: "events-eventId-carousel___el",
    path: "/el/events/:eventId()/carousel",
    meta: carouselm3aI3mcibB4mmCirHGgeWstREk2sETgxp7yCP7xHERIMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "events-eventId-carousel___en___default",
    path: "/events/:eventId()/carousel",
    meta: carouselm3aI3mcibB4mmCirHGgeWstREk2sETgxp7yCP7xHERIMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "events-eventId-carousel___en",
    path: "/en/events/:eventId()/carousel",
    meta: carouselm3aI3mcibB4mmCirHGgeWstREk2sETgxp7yCP7xHERIMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "booths-eventId-fullhouse___el",
    path: "/el/booths/:eventId()/fullhouse",
    meta: fullhouseFh_iK6aAkORdTvCd0Jw2I_45nAvqtwrto9P0D5ZUUno1cMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/fullhouse.vue")
  },
  {
    name: "booths-eventId-fullhouse___en___default",
    path: "/booths/:eventId()/fullhouse",
    meta: fullhouseFh_iK6aAkORdTvCd0Jw2I_45nAvqtwrto9P0D5ZUUno1cMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/fullhouse.vue")
  },
  {
    name: "booths-eventId-fullhouse___en",
    path: "/en/booths/:eventId()/fullhouse",
    meta: fullhouseFh_iK6aAkORdTvCd0Jw2I_45nAvqtwrto9P0D5ZUUno1cMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/fullhouse.vue")
  },
  {
    name: "booths-eventId-moderation___el",
    path: "/el/booths/:eventId()/moderation",
    meta: moderationUeMLe4zVHa6J3YfWS5c9MZSA8Q_45iLZEJ5POdagj0r4kMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/moderation.vue")
  },
  {
    name: "booths-eventId-moderation___en___default",
    path: "/booths/:eventId()/moderation",
    meta: moderationUeMLe4zVHa6J3YfWS5c9MZSA8Q_45iLZEJ5POdagj0r4kMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/moderation.vue")
  },
  {
    name: "booths-eventId-moderation___en",
    path: "/en/booths/:eventId()/moderation",
    meta: moderationUeMLe4zVHa6J3YfWS5c9MZSA8Q_45iLZEJ5POdagj0r4kMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/moderation.vue")
  },
  {
    name: "booths-eventId-guest-signin___el",
    path: "/el/booths/:eventId()/guest-signin",
    meta: guest_45signin_45EEk54kogeh8oqLS_45uGi_rdr2yf_Q71IR8i91c9RrxwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId-guest-signin___en___default",
    path: "/booths/:eventId()/guest-signin",
    meta: guest_45signin_45EEk54kogeh8oqLS_45uGi_rdr2yf_Q71IR8i91c9RrxwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId-guest-signin___en",
    path: "/en/booths/:eventId()/guest-signin",
    meta: guest_45signin_45EEk54kogeh8oqLS_45uGi_rdr2yf_Q71IR8i91c9RrxwMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId-guest-profile___el",
    path: "/el/booths/:eventId()/guest-profile",
    meta: guest_45profiletKdERjnGgPzqotwyKoS_7z6ogQotSmhJVaE674IZBFcMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-profile___en___default",
    path: "/booths/:eventId()/guest-profile",
    meta: guest_45profiletKdERjnGgPzqotwyKoS_7z6ogQotSmhJVaE674IZBFcMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-profile___en",
    path: "/en/booths/:eventId()/guest-profile",
    meta: guest_45profiletKdERjnGgPzqotwyKoS_7z6ogQotSmhJVaE674IZBFcMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___el",
    path: "/el/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45codeRXdHD_4575m0r_dn5fPhOzD7pS0imcIDyCtLwKUwbEN7oMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___en___default",
    path: "/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45codeRXdHD_4575m0r_dn5fPhOzD7pS0imcIDyCtLwKUwbEN7oMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___en",
    path: "/en/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45codeRXdHD_4575m0r_dn5fPhOzD7pS0imcIDyCtLwKUwbEN7oMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  }
]