<template>
  <div
    id="id_loader"
    v-if="_show"
    class="fixed z-[1000] inset-0 w-screen h-screen flex justify-center backdrop-blur-sm opacity-0">
    <div
      id="id_loader2"
      data-theme="instabooth-app"
      class="fixed top-1/3 text-lg px-8 py-4 flex bg-primary text-primary-content border ring-2 rounded-lg shadow-md gap-2">
      {{ sessionStore.i18nContent.working }}
      <span v-if="typeof show === 'number'">{{ show }}%</span>
      <span class="loading loading-ring loading-md"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import gsap from "gsap";
  import { RoughEase } from "gsap/EasePack";
  import { Linear } from "gsap/gsap-core";

  const sessionStore = useSessionStore();
  const props = withDefaults(
    defineProps<{
      show: boolean | number;
    }>(),
    {
      show: false,
    }
  );
  const _show = ref(false);

  onMounted(() => {
    watch(
      () => props.show,
      () => {
        if (props.show && !_show.value) {
          _show.value = true;
          const tl = gsap.timeline();
          nextTick(() => {
            tl.fromTo(
              "#id_loader",
              {
                y: 1000,
                opacity: 0,
              },
              {
                y: 0,
                opacity: 20,
                duration: 0.5,
                ease: "power1.in",
              }
            );
            // Use RoughEase for a more natural shake
            tl.fromTo(
              "#id_loader2",
              {
                x: -5,
              },
              {
                x: 5,
                duration: 3,
                ease: RoughEase.ease.config({
                  strength: 2,
                  points: 10,
                  template: Linear.easeNone,
                  randomize: false,
                }),
                clearProps: "x",
                repeat: -1, // repeat indefinitely
                yoyo: true,
              }
            );
          });
        } else if (!props.show && _show.value) {
          const tl = gsap.timeline({
            onComplete: () => {
              _show.value = false;
            },
          });
          tl.to("#id_loader", {
            y: 1000,
            duration: 0.5,
            ease: "power1.out",
          });
        }
      }
    );
  });
</script>
