<template>
  <Suspense>
    <div>
      <IdentitySetup />
      <div class="flex flex-col min-h-screen">
        <a ref="page_top" id="page_top"></a>
        <NuxtLayout>
          <NuxtPage
            :keepalive="{
              max: 2,
              include: ['events'],
            }" />
        </NuxtLayout>
        <Loader :show="loading" />
      </div>
    </div>
    <template #fallback>
      <Transition name="fade">
        <Splash />
      </Transition>
    </template>
  </Suspense>
</template>

<script setup lang="ts">
  import "animate.css";
  import dayjs from "dayjs";
  import "dayjs/locale/el";
  import localizedFormat from "dayjs/plugin/localizedFormat";
  //@ts-ignore
  import Vue3EasyDataTable from "vue3-easy-data-table";
  import "vue3-easy-data-table/dist/style.css";
  import { register } from "swiper/element/bundle";
  import { useElementBounding } from "@vueuse/core";
  import { Vue3ColorPicker } from "@cyhnkckali/vue3-color-picker";
  import "@cyhnkckali/vue3-color-picker/dist/style.css";

  const pageTopEl = useTemplateRef("page_top");
  const { top } = useElementBounding(pageTopEl);
  provide("pageTop", top);

  useNuxtApp().vueApp.component("EasyDataTable", Vue3EasyDataTable);
  useNuxtApp().vueApp.component("Vue3ColorPicker", Vue3ColorPicker);
  register();

  const sessionStore = useSessionStore();
  const loading = ref(sessionStore.loading);

  sessionStore.$onAction(({ name, after }) => {
    if (name === "setLoading") {
      after(() => (loading.value = sessionStore.loading));
    }
  });

  const { locale } = useI18n();
  dayjs.extend(localizedFormat);
  dayjs.locale(locale.value);

  const content = await queryContent<MainContent>(
    `/${useI18n().getLocaleCookie()}/main`
  ).findOne();
  sessionStore.seti18nContent(content);

  onMounted(() => {
    useAppConfig().isPWA =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches;
  });
</script>
