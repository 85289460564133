import { useSessionStore } from "./../stores/session";
import dayjs from "dayjs";
import type { Event as Booth, Photographer, Stats } from "~/model.js";
import {
  getDownloadURL,
  type FullMetadata,
  ref as _ref,
  list,
  type ListOptions,
  getMetadata,
} from "firebase/storage";
import type { ParsedContent } from "@nuxt/content";
export interface FeedPointer {
  path: string;
  url: string;
  meta: FullMetadata;
}
import type { DocContainer, DocBody } from "~/model";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
  type DocumentSnapshot,
} from "firebase/firestore";

export const handleMobileNumberInput = (event: Event) => {
  const { key } = event as KeyboardEvent;
  if (
    key === "Tab" ||
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    return;
  }

  if (!Number.isInteger(Number.parseInt(key))) {
    event.preventDefault();
  }
};

export const formatDateToLocale = (isoDateString: string) =>
  dayjs(isoDateString).format("llll");

export interface MainContent extends ParsedContent {
  welcome1: string;
  welcome2: string;
  entrance: string;
  eventStartsIn: string;
  eventIsClosed: string;
  back: string;
  cancel: string;
  working: string;
  countdown: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  };
  signInIntro: string;
  signIn: string;
  signOut: string;
  userProfile: string;
  photoBoothUploadIntro: string;
  photoBoothCommentPlaceholder: string;
  photoBoothTake: string;
  photoBoothUpload: string;
  photoBoothUploadFailed: string;
  inviteIntro: string;
  inviteOpenInNew: string;
  userProfileIntro1: string;
  userProfileIntro2: string;
  userProfileIntro3: string;
  userProfileNickname: string;
  confettiMsg1: string;
  confettiMsg2: string;
  startCarousel: string;
  showStats: string;
  calculating: string;
  calculated: string;
  refresh: string;
  totalContributors: string;
  totalPhotos: string;
  totalSize: string;
  contributorsList: string;
  contributorsSorting: string;
  contributor: string;
  photoCount: string;
  signInIntro2: string;
  send: string;
  termsOfUse: string;
  privacyPolicy: string;
  consent_1_3: string;
  consent_2_3: string;
  consent_3_3: string;
  captureTargetMessage: string;
  captureTargetMessage2: string;
  greeting: string;
  fileTooBig: string;
  boothMediaDeletionHeaderConfirmation: string;
  boothMediaDeletionConfirmation1: string;
  boothMediaDeletionConfirmation2: string;
  boothMediaDeletionYesConfirmation: string;
  moderation: string;
}

export const getTenantId = async () =>
  (await useSessionStore().auth?.currentUser?.getIdTokenResult())?.claims
    .tenantId as string;

export const buildAlbumPath = async (ev: EventInfo) => {
  const route = useRoute();

  const { eventId } = route.params;
  if (!eventId) return;

  const tenantId = ev.tenantId;
  const ownerUid = ev.ownerUid;

  return `/albums/${tenantId}/${ownerUid}/${eventId}`;
};

export const buildBundlePath = async (ev: EventInfo) => {
  const route = useRoute();

  const { eventId } = route.params;
  if (!eventId) return;

  const tenantId = ev.tenantId;
  const ownerUid = ev.ownerUid;

  return `/bundles/${tenantId}/${ownerUid}/${eventId}`;
};

export const assertEventId = async (force?: boolean) => {
  const route = useRoute();
  const { eventId } = route.params;
  if (!eventId) return;

  const sessionStore = useSessionStore();
  if (sessionStore.eventName === eventId && !force) return;

  const evtRef = _ref(sessionStore.storage!, `/toc/${eventId}.json`);
  try {
    const url = await getDownloadURL(evtRef);
    sessionStore.setEventInfo(await $fetch<EventInfo>(url));
    sessionStore.setEventName(eventId as string);

    if (sessionStore.eventInfo.value?.moderated) {
      const auth = sessionStore.auth!;
      if (!auth?.currentUser?.uid) return false;
      const d = await getDoc(
        doc(
          sessionStore.db!,
          `events/${eventId}/moderators/${auth.currentUser.phoneNumber}`
        )
      );
      sessionStore.setModerator(d.exists());
    }
  } catch (error) {
    console.error(error);
    throw createError({ statusCode: 404, fatal: true });
  } finally {
  }
};

export const getFirebaseStoragePage = async (
  prefix: string,
  listOptions?: ListOptions
) => {
  const sessionStore = useSessionStore();
  const maxResults = listOptions?.maxResults ?? 100;
  const pageToken = listOptions?.pageToken;

  // Create a reference under which you want to list
  try {
    const listRef = _ref(sessionStore.storage!, prefix);
    return await list(listRef, { maxResults, pageToken });
  } catch (e) {
    //ignore
  }
};

export const toDocData = <T extends DocBody>(ref: DocumentSnapshot) => {
  return <DocContainer<T>>{ id: ref.id, data: ref.data() as T };
};

export const isEventOngoing = (e: Booth) =>
  dayjs().isAfter(e.startTime) && dayjs().isBefore(e.endTime);

export const isEventUpcoming = (e: Booth) => dayjs().isBefore(e.startTime);

export const isEventCompleted = (e: Booth) => dayjs().isAfter(e.endTime);

export const isEventReadyForBundling = (e: Booth) =>
  isEventCompleted(e) &&
  !e.actions?.bundled?.timestamp &&
  !e.actions?.downloaded?.length &&
  !e.actions?.archived;

export const isEventReadyForDownload = (e: Booth) =>
  isEventCompleted(e) &&
  e.actions?.bundled?.timestamp &&
  !isEventReadyForArchive(e) &&
  !isEventArchived(e);

export const isEventReadyForArchive = (e: Booth) => {
  if (!isEventCompleted(e) || !e?.actions?.bundled?.files || isEventArchived(e))
    return false;

  for (const b of e.actions.bundled.files) {
    if (!e.actions!.downloaded?.find((d) => d.file === b)) {
      return false;
    }
  }
  return true;
};

export const isEventArchived = (e: Booth) =>
  !e.actions?.archived?.timestamp ? false : true;

export const isCarouselAvailable = (e: Booth) =>
  isEventOngoing(e) || isEventReadyForBundling(e);

export const formatFileSize = (bytes: number) => {
  const { locale } = useI18n();
  const isGB = bytes / Math.pow(1000.0, 3) >= 1.0;

  const s = Intl.NumberFormat(locale.value, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(bytes / (isGB ? Math.pow(1000, 3) : Math.pow(1000, 2)));

  return `${s} ${isGB ? "GB" : "MB"}`;
};

export const calcAndUpdateStats = async (event: DocContainer<Booth>) => {
  let lastPageToken: string | undefined;
  let first = true;

  const stats = <Stats>{
    photoCount: 0,
    videoCount: 0,
    photoStorageSize: 0,
    timestamp: serverTimestamp() as unknown,
  };

  const sessionStore = useSessionStore();
  const countPath = async (path: string) => {
    while (first || lastPageToken) {
      first = false;
      const page = await getFirebaseStoragePage(path, {
        maxResults: 100,
        pageToken: lastPageToken,
      });
      lastPageToken = page?.nextPageToken;

      if (page) {
        const metas = <Array<Promise<FullMetadata>>>[];
        for (const item of page.items) {
          metas.push(getMetadata(item));
        }

        for (const meta of await Promise.all(metas)) {
          if (
            meta.contentType?.startsWith("image") ||
            meta.contentType?.startsWith("video")
          ) {
            const newPhoto = meta.contentType?.startsWith("image") ? 1 : 0;
            const newVideo = meta.contentType?.startsWith("video") ? 1 : 0;
            stats.photoCount += newPhoto;
            stats.videoCount += newVideo;
            stats.photoStorageSize = stats.photoStorageSize + meta.size;
          }
        }
      }
    }
  };

  const albumPath = (await buildAlbumPath(event.data))!;
  await countPath((await buildAlbumPath(event.data))!);
  if (event.data.moderated) {
    await countPath(`${albumPath}/moderated/accepted`);
    await countPath(`${albumPath}/moderated/rejected`);
  }

  updateDoc(
    doc(sessionStore.db!, "events", event.id, "stats", "overview"),
    stats as any
  );
};

export const getPhotoTimeFromName = (name: string) => {
  return dayjs(name.split("_")[0]).toDate();
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toMediaItemUrl = async (
  url: string,
  options: {
    type: "image" | "video";
    thumbnail?: boolean;
    withOverlay?: boolean;
    optimize?: boolean;
  }
) => {
  const _url = new URL(url);
  const fbStorage = _url.hostname === "firebasestorage.googleapis.com";
  // let imagekit = false;

  if (fbStorage && options.thumbnail) {
    const sessionStore = useSessionStore();
    const token = await sessionStore.getAppCheckToken();
    if (options.type === "image") {
      return `/thumbnails/photo?maxSize=100&token=${token}&src=${btoa(url)}`;
    } else {
      return `/thumbnails/video?second=1&maxSize=100&token=${token}&src=${btoa(url)}`;
    }
  }
  if (fbStorage && options.optimize) {
    const sessionStore = useSessionStore();
    const token = await sessionStore.getAppCheckToken();
    if (options.type === "image") {
      return `/optimize/photo?maxSize=600&token=${token}&src=${btoa(url)}`;
    } else {
      // streaming is lost if used
      // return `/optimize/video?token=${token}&src=${btoa(url)}`;
    }
  }

  let resp = `${_url.protocol}//`;

  resp += _url.host;

  resp += _url.pathname;

  resp += _url.search;

  // if (imagekit && options.type === "image" && options.withOverlay) {
  //   resp += "&tr=l-image,i-qr-branding.svg,lx-N50,ly-N50,w-bw_div_6,l-end";
  // }

  return resp;
};

export const isRunningLocally = () => useRuntimeConfig().public.emulate;

export const getMediaUrl = async (
  eventId: String,
  fullPath: string,
  type: "image" | "video",
  thumbnail?: boolean,
  optimize?: boolean
) => {
  let cache, resp;
  const cacheName = `booth${eventId}`;
  const cacheKey = fullPath + (thumbnail ? "/thumbnail" : "");

  try {
    cache = await window.caches.open(cacheName);
    resp = await cache.match(cacheKey);
    if (resp?.ok) {
      const blob = await resp.blob();
      return URL.createObjectURL(blob);
    } else {
      cache.delete(cacheKey);
    }
  } catch (error) {}

  const sessionStore = useSessionStore();
  const pref = _ref(sessionStore.storage!, fullPath);
  let url = await getDownloadURL(pref);

  if (type === "image") {
    url = await toMediaItemUrl(url, { type: "image", thumbnail, optimize });
  } else {
    url = await toMediaItemUrl(url, { type: "video", thumbnail, optimize });
  }

  resp = await fetch(url, { method: "GET" });
  if (cache && resp.ok) {
    cache.put(cacheKey, resp.clone());
  }
  return URL.createObjectURL(await resp.blob());
};

export const getMediaMeta = async (fullPath: string) => {
  const cache = window.sessionStorage;
  const resp = cache.getItem(fullPath);
  if (resp) {
    // console.log("cahce hit for meta " + path);
    return JSON.parse(resp) as FullMetadata;
  }

  const sessionStore = useSessionStore();
  const pref = _ref(sessionStore.storage!, fullPath);
  const meta = await getMetadata(pref);
  cache.setItem(fullPath, JSON.stringify(meta));
  return meta;
};

export const getAsFile = async (path: string) => {
  const sessionStore = useSessionStore();
  const photoRef = _ref(sessionStore.storage!, path);
  let url = await getDownloadURL(photoRef);
  const photo = path.endsWith(".jpg") || path.endsWith(".jpeg");
  url = await toMediaItemUrl(url, {
    type: photo ? "image" : "video",
    withOverlay: true,
  });

  // console.log(url);
  const blob = await $fetch<Blob>(url, {
    method: "GET",
  });

  return new File([blob], photo ? "instabooth.jpg" : "instabooth.mp4", {
    type: photo ? "image/jpeg" : "video/mp4",
  });
};

export const resolveIconFile = async (
  src: EventInfo | string,
  forceNew = false
) => {
  const sessionStore = useSessionStore();
  const iconPath =
    typeof src === "string" ? src : `toc/${sessionStore.eventName}.icon.png`;
  if (!forceNew && !iconPath) {
    return "/img/brand.png";
  }

  const iconRef = _ref(sessionStore.storage!, iconPath);
  try {
    return await getDownloadURL(iconRef);
  } catch (error) {
    return "/img/brand.png";
  }
};

export const replaceQueryParam = (
  url: string,
  param: string,
  newValue: string
) => {
  const regex = new RegExp(`([?&])${param}=([^&]*)`);
  const newParam = `${param}=${newValue}`;
  if (url.match(regex)) {
    return url.replace(regex, `$1${newParam}`);
  } else {
    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}${newParam}`;
  }
};

export const getThemeUrl = async () => {
  const sessionStore = useSessionStore();
  const iconRef = _ref(
    sessionStore.storage!,
    `toc/${useRoute().params.eventId}.theme.css`
  );
  try {
    return await getDownloadURL(iconRef);
  } catch (error) {
    return "";
  }
};

export const updatePhotographer = async (eventId: string, locale: string) => {
  const sessionStore = useSessionStore();
  const path = `/events/${eventId}/photographers/${sessionStore.auth!.currentUser!.uid}`;

  const ph = <Photographer>{
    locale: locale,
    userHandle: sessionStore.auth!.currentUser!.displayName,
    phoneNumber: sessionStore.auth!.currentUser!.phoneNumber,
  };
  const d = await getDoc(doc(sessionStore.db!, path));
  if (!d.exists()) {
    ph.timestamp = serverTimestamp();
  }
  try {
    await setDoc(doc(sessionStore.db!, path), ph, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
