<template></template>
<script setup lang="ts">
  import type { FirebaseError } from "firebase/app";
  import {
    isSignInWithEmailLink,
    signInWithEmailLink,
    updateProfile,
  } from "firebase/auth";

  const sessionStore = useSessionStore();
  sessionStore.initialize();

  const localeRoute = useLocaleRoute();
  const url = useRequestURL();
  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(sessionStore.auth!, url.href)) {
    await sleep(3000);

    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem("emailForSignIn");
    window.localStorage.removeItem("emailForSignIn");
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    try {
      const result = await signInWithEmailLink(
        sessionStore.auth!,
        email!,
        url.href
      );
      await updateProfile(result.user, {
        displayName: result.user.email?.split("@")[0],
      });
      await sessionStore.auth?.currentUser?.reload();
      await navigateTo(localeRoute("events"));
    } catch (error: any) {
      console.error((error as FirebaseError).message);
      navigateTo(localeRoute("/"));
    }
  } else {
    await sleep(1000);
    await sessionStore.auth?.authStateReady();
  }
</script>
